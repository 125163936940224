import {useState} from "react";
import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";

export default function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.animeId}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.animeName}
                </TableCell>
                <TableCell align="right">{row.description}</TableCell>
                <TableCell align="right">{row.imageUrl}</TableCell>
                <TableCell align="right">{row.lastEp}</TableCell>
                <TableCell align="right">{row.latestLastEp}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                Esp
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>EspID</TableCell>
                                        <TableCell>Website</TableCell>
                                        <TableCell align="right">Fetch Url</TableCell>
                                        <TableCell align="right">Last Ep</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                        <TableCell align="right">EspEp ID</TableCell>
                                        <TableCell align="right">Name Ep</TableCell>
                                        <TableCell align="right">Ep URL</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.esp.map((esp) => (
                                        <TableRow key={esp.espEpId}>
                                            <TableCell component="th" scope="row">
                                                {esp.espId}
                                            </TableCell>
                                            <TableCell>{esp.website}</TableCell>
                                            <TableCell align="right">{esp.fetchUrl}</TableCell>
                                            <TableCell align="right">
                                                {esp.lastEp}
                                            </TableCell>
                                            <TableCell align="right">{esp.status}</TableCell>
                                            <TableCell align="right">{esp.espEpId}</TableCell>
                                            <TableCell align="right">{esp.nameEp}</TableCell>
                                            <TableCell align="right">{esp.epUrl}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}