import '../style/Navbar.css';
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import LoginIcon from "@mui/icons-material/Login";
import {useDispatch, useSelector} from "react-redux";
import {logoutService} from "../api/loginService";
import {FETCH_ANIMES, LOGOUT_ACTION, LOGOUT_ADMIN_ACTION} from "../actions/types";
import {getAllAnimePaginationService} from "../api/animeService";
import {MenuOutlined} from "@ant-design/icons";
import {Menu} from "antd";
import { Button as AntButton, Modal as AntModal} from 'antd'
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import { PersonOutline} from "@mui/icons-material";

export default function NavigationBar() {
    const [collapsed, setCollapsed] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalRegisterOpen, setIsModalRegisterOpen] = useState(false);

    // redux
    const isLogin = useSelector(state => state.login.isLogin)
    const token = useSelector(state => state.login.token)
    const account = useSelector(state => state.login.account)
    const tokenExpire = useSelector(state => state.login.tokenExpire)
    const dispatch = useDispatch()

    const { Item: MenuItem } = Menu;

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    useEffect(() => {
        async function autoLogout() {
            if (isLogin) {
                const now = Date.now()
                if (now >= tokenExpire) {
                    const res = await logoutService(token)

                    if (res === '500') {
                        alert("System error can't logout")
                    }
                    dispatch({
                        type: LOGOUT_ACTION,
                    })

                    dispatch({
                        type: LOGOUT_ADMIN_ACTION,
                    })
                }
            }
        }

        autoLogout();
    }, [dispatch, tokenExpire, token,isLogin])

    async function logout() {
        const res = await logoutService(token)

        if (res === '500') {
            alert("System error can't logout")
        }

        dispatch({
            type: LOGOUT_ACTION,
        })

        dispatch({
            type: LOGOUT_ADMIN_ACTION,
        })
        setIsModalOpen(false);
    }

    const handleRegisterNow = () => {
        setIsModalOpen(false);
        setIsModalRegisterOpen(true)
    }
    const handleCancelRegister = () => {
        setIsModalRegisterOpen(false);
    };

    async function handleHomeBtn(){
        const animes = await getAllAnimePaginationService("1");
        dispatch({
            type: FETCH_ANIMES,
            payload: animes
        })
    }

    return (
        <>
            <div className="container nav-bar d-flex align-items-center justify-content-between py-3 px-3">
                <div className="right d-flex">
                    <Link to = "/">
                        <div className="d-flex align-items-center ps-3 me-3" style={{color: 'white', fontFamily: 'Kanit, sans-serif'}} onClick={handleHomeBtn}>Anime-Portal</div>
                    </Link>
                    {account?.roles.includes('admin') ?
                        <div className="menu d-none d-sm-flex">
                            <Link to="/manageAnime">
                                <div className="menu-item" style={{color: 'white'}}>Manage Anime</div>
                            </Link>
                        </div> :
                        null
                    }

                </div>

                <div className="top-right d-flex">
                    {isLogin ?
                        <div className="logout">
                            <Link to="/profile">
                                <AntButton className="profile" style={{marginRight: '20px'}}>Profile<PersonOutline/></AntButton>
                            </Link>
                            <AntButton className="signout"  onClick={logout}>Sign Out<LoginIcon/></AntButton>
                        </div> :
                        <div className="login">
                            <AntButton className="signin"
                                       onClick={showModal}>Sign In</AntButton>
                            <AntModal style={{fontFamily: 'Kanit, sans-serif'}} title="Login" open={isModalOpen}
                                      onCancel={handleCancel} footer={null}>
                                <LoginForm handleRegisterNow={handleRegisterNow}/>
                            </AntModal>
                            <AntModal style={{fontFamily: 'Kanit, sans-serif'}} title="Register" open={isModalRegisterOpen}
                                      onCancel={handleCancelRegister} footer={null}>
                                <RegisterForm />
                            </AntModal>
                        </div>
                    }
                    <div className="ms-3 align-items-center d-flex d-sm-none">
                        <div className="d-flex align-items-center" onClick={toggleCollapsed}>
                            <span className="menu"><MenuOutlined/></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper d-block d-sm-none">
                <Menu
                    mode="inline" // Use inline mode for the menu
                    theme="dark" // Use dark theme for the menu
                    inlineCollapsed={collapsed} // Control whether the menu is collapsed
                >
                    <MenuItem key="1" selected>
                        <span>หน้าแรก</span> {/* First menu item */}
                    </MenuItem>
                </Menu>
            </div>
        </>
    )
}