// add this line allowed react-bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import {BrowserRouter} from "react-router-dom";
import configureStore from "./store";
import './style/index.css'

const {store, persistor} = configureStore()


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </PersistGate>
    </Provider>
    , document.getElementById('root')
);