import '../style/LoginForm.css'
import {Form, Input, Checkbox, Button, Row, Col, Typography} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {loginService, logoutService} from "../api/loginService";
import {
    LOGIN_ACTION,
    LOGIN_SYSTEM_FAILED,
    LOGIN_VALIDATION_FAILED,
    LOGOUT_ACTION,
    LOGOUT_ADMIN_ACTION
} from "../actions/types";
import jwt_decode from "jwt-decode";
import {TOKEN_EXPIRE_TIME} from "../common";
import {useDispatch} from "react-redux";


const { Link } = Typography;

export default function LoginForm(props) {
    const [form] = Form.useForm();
    const dispatch = useDispatch()

    // Function to handle form submission
    const handleSubmit = async (values) => {
        const token = await loginService(values.userName,values.password)

        if(token === '401'){
            dispatch({
                type: LOGIN_VALIDATION_FAILED,
                payload: '401'
            })
            alert("Username or Password invalid")
            return;
        }else if(token === '500'){
            dispatch({
                type: LOGIN_SYSTEM_FAILED,
                payload: '500'
            })
            alert("This time system has a problem")
            return;
        }

        const accessToken = token.accessToken

        const decoded = jwt_decode(accessToken);

        const account = {
            username: decoded.username,
            roles: decoded.roles,
            firstName: decoded.firstName,
            lastName: decoded.lastName,
            email: decoded.email,
            animeIds: decoded.animeIds,
            userId: decoded.userId
        }

        dispatch({
            type: LOGIN_ACTION,
            payload: {token: accessToken, account}
        })

        setTimeout(async ()=>{
            const res = await logoutService(accessToken)

            if(res === '500'){
                alert("System error can't logout")
            }

            dispatch({
                type: LOGOUT_ACTION,
            })

            dispatch({
                type: LOGOUT_ADMIN_ACTION,
            })

        },TOKEN_EXPIRE_TIME)
    };

    // Function to handle forgot password click
    const handleForgotPassword = () => {
        // Perform forgot password actions
    };

    return (
        <div>
            <Form
                form={form}
                className="login-form"
                onFinish={handleSubmit}
            >
                {/* Username field */}
                <Form.Item
                    name="userName"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input
                        prefix={<UserOutlined />}
                        placeholder="Username"
                    />
                </Form.Item>

                {/* Password field */}
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>

                {/* Remember me checkbox and forgot password link */}
                <Row className="login-form-margin">
                    <Col span={12}>
                        <Form.Item name="remember" valuePropName="checked">
                            <Checkbox style={{fontFamily: 'Kanit, sans-serif'}}>Remember me</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Link onClick={handleForgotPassword} style={{color: 'black', fontFamily: 'Kanit, sans-serif'}} className="login-form-forgot">
                            Forgot password
                        </Link>
                    </Col>
                </Row>

                {/* Log in button */}
                <Form.Item>
                    <Button
                        htmlType="submit"
                        style={{ width: '100%' ,fontFamily: 'Kanit, sans-serif', backgroundColor:'black', color:'white'}}
                        className="login-form-button login-form-margin mt-3 mb-3"
                    >
                        Log in
                    </Button>
                </Form.Item>

                {/* Register now link */}
                <div >
                    <Link style={{fontFamily: 'Kanit, sans-serif', color:'black'}} onClick={props.handleRegisterNow}>Or register now!</Link>
                </div>
            </Form>
        </div>
    );
}


