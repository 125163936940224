import {api, BASE_NOTIFICATION_ENDPOINT, GET_AUTHORIZE_HEADER_WITH_TOKEN} from "../common";

export async function registerLineNotificationService(authCode,token){
    let registerLineNotification

    try {
        registerLineNotification = await api.post(BASE_NOTIFICATION_ENDPOINT,{authCode},GET_AUTHORIZE_HEADER_WITH_TOKEN(token))
    }catch (e){
        return '500'
    }

    return  registerLineNotification.data
}

export async function removeLineNotificationService(token){
    let removeLineNotification

    try {
        removeLineNotification = await api.delete(BASE_NOTIFICATION_ENDPOINT,GET_AUTHORIZE_HEADER_WITH_TOKEN(token))
    }catch (e){
        return '500'
    }

    return  removeLineNotification.data
}