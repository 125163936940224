import {api, BASE_ANIME_ENDPOINT, BASE_ANIME_WITH_PARAM_ENDPOINT, GET_AUTHORIZE_HEADER_WITH_TOKEN} from "../common";

export async function getAllAnimeService(token) {
    const getAllAnime = await api.get(BASE_ANIME_ENDPOINT,GET_AUTHORIZE_HEADER_WITH_TOKEN(token))

    return getAllAnime.data
}

export async function getAllAnimePaginationService(offset) {
    const getAllAnime = await api.get(BASE_ANIME_ENDPOINT+'/pagination/'+offset)

    return getAllAnime.data
}

export async function getAnimeService(animeId){
    const getAnime = await api.get(BASE_ANIME_WITH_PARAM_ENDPOINT(animeId))

    return getAnime.data
}

export async function createAnimeService(animeName,description,imageUrl,trailerUrl,token){
    let createAnime
    try {
        createAnime = await api.post(BASE_ANIME_ENDPOINT, {
            animeName,
            description,
            imageUrl,
            trailerUrl
        }, GET_AUTHORIZE_HEADER_WITH_TOKEN(token))
    }catch (e){
        if(e.toString().includes('401') || e.toString().includes('400')){
            return '401';
        }else if(e.toString().includes('409')){
            return '409'
        }

        return '500';
    }

    return  createAnime.data
}

export async function updateAnimeService(animeId,data,token){
    let updateAnime
    try {
        updateAnime = await api.put(BASE_ANIME_WITH_PARAM_ENDPOINT(animeId),data,GET_AUTHORIZE_HEADER_WITH_TOKEN(token))
    }catch (e){
        if(e.toString().includes('401') || e.toString().includes('400')){
            return '401';
        }else if(e.toString().includes('409')){
            return '409'
        }
        return '500';
    }

    return updateAnime.data
}

export async function deleteAnimeService(animeId,token){
    let deleteAnime
    try {
        deleteAnime = await api.delete(BASE_ANIME_WITH_PARAM_ENDPOINT(animeId),GET_AUTHORIZE_HEADER_WITH_TOKEN(token))
    }catch (e){
        if(e.toString().includes('401') || e.toString().includes('400')){
            return '401';
        }
        return '500';
    }

    return deleteAnime.data
}