import {api, BASE_SUBSCRIBE_WITH_PARAM_ENDPOINT, GET_AUTHORIZE_HEADER_WITH_TOKEN} from "../common";

export async function subscribeService(animeId,token){
    let subscribe

    try{
        subscribe = await api.post(BASE_SUBSCRIBE_WITH_PARAM_ENDPOINT(animeId),null,GET_AUTHORIZE_HEADER_WITH_TOKEN(token))
    }catch (e){
        if (e.toString().includes('404')) {
            return '404'
        } else if (e.toString().includes('400') || e.toString().includes('401')) {
            return '400'
        }

        return '500'
    }

    return subscribe.data
}

export async function unsubscribeService(animeId,token){
    let unsubscribe

    try {
        unsubscribe = await api.delete(BASE_SUBSCRIBE_WITH_PARAM_ENDPOINT(animeId),GET_AUTHORIZE_HEADER_WITH_TOKEN(token))
    }catch (e){
        if (e.toString().includes('404')) {
            return '404'
        } else if (e.toString().includes('400') || e.toString().includes('401')) {
            return '400'
        }
        return '500'
    }

    return unsubscribe.data
}