export const FETCH_ANIMES = 'FETCH_ANIMES'
export const WATCH_ANIME = 'WATCH_ANIME'

export const LOGIN_ACTION = 'LOGIN_ACTION'
export const LOGOUT_ACTION = 'LOGOUT_ACTION'
export const LOGIN_VALIDATION_FAILED = 'LOGIN_VALIDATION_FAILED'
export const LOGIN_SYSTEM_FAILED = 'LOGIN_SYSTEM_FAILED'

export const REGISTER_LINE_NOTIFICATION_ACTION = 'REGISTER_LINE_NOTIFICATION_ACTION'
export const REMOVE_LINE_NOTIFICATION_ACTION = 'REMOVE_LINE_NOTIFICATION_ACTION'

export const SUBSCRIBE_ACTION = 'SUBSCRIBE_ACTION'
export const UNSUBSCRIBE_ACTION = 'UNSUBSCRIBE_ACTION'

export const LOGOUT_ADMIN_ACTION = 'LOGOUT_ADMIN_ACTION'
export const  FETCH_ANIMES_ADMIN = 'FETCH_ANIMES_ADMIN'
export const CREATE_ANIME_ACTION = 'CREATE_ANIME_ACTION'
export const UPDATE_ANIME_ACTION = 'UPDATE_ANIME_ACTION'
export const DELETE_ANIME_ACTION = 'DELETE_ANIME_ACTION'
export const CREATE_ESP_ACTION = 'CREATE_ESP_ACTION'