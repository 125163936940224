import {api, GET_AUTHORIZE_HEADER_WITH_TOKEN, LOGIN_ENDPOINT, LOGOUT_ENDPOINT} from "../common";

export async function loginService(username, password) {
    let token;
    try {
        token = await api.post(LOGIN_ENDPOINT, {
            username,
            password
        })
    }catch (e){
        if(e.toString().includes('401') || e.toString().includes('409') || e.toString().includes('400')){
            return '401';
        }
        return '500';
    }

    return token.data
}

export async function logoutService(token){
    try {
         await api.post(LOGOUT_ENDPOINT, null,GET_AUTHORIZE_HEADER_WITH_TOKEN(token))
    }catch (e){
        return '500';
    }

    return '200'
}