import {api, BASE_EXTERNAL_WEB_ENDPOINT, GET_EXTERNAL_WEB_ENDPOINT} from "../common";

export async function getExternalWebService(externalWebId){
    const getExternalWeb = await api.get(GET_EXTERNAL_WEB_ENDPOINT(externalWebId))

    return getExternalWeb.data
}

export async function getAllExternalWebService(){
    const getAllExternalWeb = await api.get(BASE_EXTERNAL_WEB_ENDPOINT)

    return getAllExternalWeb.data
}