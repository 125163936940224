import React from 'react';
import '../style/Layout.css';
import AppHeader from "../components/AppHeader";
import { Layout as AntLayout} from 'antd';

export default function Layout({ children }) {
    const { Header, Footer, Content } = AntLayout;
    return (
        <AntLayout>
            <Header className="rc-header">
                <AppHeader />
            </Header>

            <Content className="rc-content">
                {/* Render the children prop instead of Outlet */}
                <div className="container" style={{background: "transparent"}}>
                    {children}
                </div>
            </Content>

            <Footer className="rc-footer">
                <div className="container neon-text-blue">© 2024 Anime-Portal</div>
            </Footer>
        </AntLayout>
    );
}
