import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";
import Row from "./Row";
import {useDispatch, useSelector} from "react-redux";
import {Modal, Button, Form} from "react-bootstrap"
import {useEffect, useState} from "react";
import {
    createAnimeService,
    deleteAnimeService,
    getAllAnimeService,
    getAnimeService,
    updateAnimeService
} from "../api/animeService";
import {
    CREATE_ANIME_ACTION,
    CREATE_ESP_ACTION,
    DELETE_ANIME_ACTION,
    FETCH_ANIMES_ADMIN,
    UPDATE_ANIME_ACTION
} from "../actions/types";
import {getAllExternalWebService} from "../api/externalWebService";
import {ANIME_SUGOI} from "../common";
import {createEspService, deleteEspService, updateEspService} from "../api/espService";

export default function ManageAnime() {
    const account = useSelector(state => state.login.account)
    const adminAnimes = useSelector(state => state.admin.adminAnimes)
    const websites = useSelector(state => state.admin.websites)
    const token = useSelector(state => state.login.token)

    const [isFetch, setIsFetch] = useState({isFetch: false})

    const [modalCreateAnimeIsOpen, setCreateAnimeIsOpen] = useState(false);
    const [animeId, setAnimeId] = useState("");
    const [animeName, setAnimeName] = useState("");
    const [animeDesc, setAnimeDesc] = useState("");
    const [animeImageUrl, setAnimeImageUrl] = useState("");
    const [animeTrailerUrl, setAnimeTrailerUrl] = useState("");

    const [modalUpdateAnimeIsOpen, setUpdateAnimeIsOpen] = useState(false);

    const [modalDeleteAnimeIsOpen, setDeleteAnimeIsOpen] = useState(false);

    const [modalCreateEspIsOpen, setCreateEspIsOpen] = useState(false);
    const [espAnimeName, setEspAnimeName] = useState("");
    const [espAnimeWebsite, setEspAnimeWebsite] = useState("");
    const [fetchAnimeUrl, setFetchAnimeUrl] = useState("");

    const [modalUpdateEspIsOpen, setUpdateEspIsOpen] = useState(false);

    const [modalDeleteEspIsOpen, setDeleteEspIsOpen] = useState(false);

    const [showFormUpdateAnimeName, setShowFormUpdateAnimeName] = useState(false)
    const [showFormUpdateAnimeDesc, setShowFormUpdateAnimeDesc] = useState(false)
    const [showFormUpdateAnimeImageUrl, setShowFormUpdateAnimeImageUrl] = useState(false)
    const [showFormUpdateAnimeTrailerUrl, setShowFormUpdateAnimeTrailerUrl] = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchAnimeAdmin() {
            if (!account || !account.roles.includes('admin')) {
                return
            } else {
                const adminAnimes = []
                const animes = await getAllAnimeService(token);
                const getAllExternalWebKey = {}
                const getAllExternalWeb = await getAllExternalWebService()

                getAllExternalWeb.forEach((externalWeb) => {
                    getAllExternalWebKey[externalWeb.externalWebId] = externalWeb.website
                })

                for (const anime of animes) {
                    const animeId = anime.animeId

                    const getAnime = await getAnimeService(animeId)


                    const esp = []

                    if (getAnime.espWithEpisodes.length === 0) {
                    } else {
                        for (const espAnime of getAnime.espWithEpisodes) {
                            let count = 0
                            for (const espEpAnime of espAnime.espEpisode) {
                                if (count === 0) {
                                    esp.push({
                                        espId: espAnime.esp.espId,
                                        website: getAllExternalWebKey[espEpAnime.externalWebId],
                                        fetchUrl: espAnime.esp.url,
                                        lastEp: espAnime.esp.lastEp,
                                        status: espAnime.esp.status,
                                        espEpId: espEpAnime.espEpId,
                                        nameEp: espEpAnime.nameEpisode,
                                        epUrl: espEpAnime.episodeUrl
                                    })
                                } else {
                                    esp.push({
                                        espId: "",
                                        website: "",
                                        fetchUrl: "",
                                        lastEp: "",
                                        status: "",
                                        espEpId: espEpAnime.espEpId,
                                        nameEp: espEpAnime.nameEpisode,
                                        epUrl: espEpAnime.episodeUrl
                                    })
                                }
                                count += 1
                            }
                        }
                    }

                    adminAnimes.push({
                        animeId,
                        animeName: anime.animeName,
                        description: anime.description,
                        imageUrl: anime.imageUrl,
                        trailerUrl: anime.trailerUrl,
                        lastEp: anime.lastEp,
                        latestLastEp: anime.latestLastEp,
                        esp
                    })
                }

                dispatch({
                    type: FETCH_ANIMES_ADMIN,
                    payload: {adminAnimes, websites: getAllExternalWeb}
                })

                setIsFetch({isFetch: true})
            }
        }

        fetchAnimeAdmin();
    }, [dispatch,account, token])

    function handleAnimeIdChange(event) {
        setAnimeId(event.target.value)
    }

    function handleAnimeNameChange(event) {
        setAnimeName(event.target.value)
    }

    function handleAnimeDescChange(event) {
        setAnimeDesc(event.target.value)
    }

    function handleAnimeImageUrlChange(event) {
        setAnimeImageUrl(event.target.value)
    }

    function handleAnimeTrailerUrlChange(event) {
        setAnimeTrailerUrl(event.target.value)
    }

    async function handleCreateAnime() {
        // Call API Create Anime
        const anime = await createAnimeService(animeName, animeDesc, animeImageUrl, animeTrailerUrl, token)

        if (anime === '401') {
            alert("Invalid field not correct")
            return
        } else if (anime === '409') {
            alert("Anime already exist")
            return
        } else if (anime === '500') {
            alert("System error")
            return
        }
        dispatch({
            type: CREATE_ANIME_ACTION,
            payload: {
                ...anime,
                lastEp: "",
                latestLastEp: "",
                esp: []
            }
        })

        alert("Create Anime Success")
        setAnimeName("")
        setAnimeDesc("")
        setAnimeImageUrl("")
        setAnimeTrailerUrl("")
        closeCreateAnimeModal()
    }

    function handleCheckBoxUpdateAnimeName(event) {
        if (event.target.checked) {
            setShowFormUpdateAnimeName(true)
        } else {
            setShowFormUpdateAnimeName(false)
        }
    }

    function handleCheckBoxUpdateAnimeDesc(event) {
        if (event.target.checked) {
            setShowFormUpdateAnimeDesc(true)
        } else {
            setShowFormUpdateAnimeDesc(false)
        }
    }

    function handleCheckBoxUpdateAnimeImageUrl(event) {
        if (event.target.checked) {
            setShowFormUpdateAnimeImageUrl(true)
        } else {
            setShowFormUpdateAnimeImageUrl(false)
        }
    }

    function handleCheckBoxUpdateAnimeTrailerUrl(event) {
        if (event.target.checked) {
            setShowFormUpdateAnimeTrailerUrl(true)
        } else {
            setShowFormUpdateAnimeTrailerUrl(false)
        }
    }

    async function handleUpdateAnime() {
        //Call Update Anime
        const updateData = {}
        if (showFormUpdateAnimeName) {
            updateData.animeName = animeName
        }
        if (showFormUpdateAnimeDesc) {
            updateData.description = animeDesc
        }
        if (showFormUpdateAnimeImageUrl) {
            updateData.imageUrl = animeImageUrl
        }
        if (showFormUpdateAnimeTrailerUrl) {
            updateData.trailerUrl = animeTrailerUrl
        }

        const updateAnime = await updateAnimeService(animeId, updateData, token)

        if (updateAnime === '401') {
            alert("Invalid field not correct")
            return
        } else if (updateAnime === '409') {
            alert("Anime name already exist")
            return
        } else if (updateAnime === '500') {
            alert("System error")
            return
        }

        updateData.animeId = animeId

        dispatch({
            type: UPDATE_ANIME_ACTION,
            payload: updateData
        })

        alert("Update anime success")
        setAnimeId("")
        setAnimeName("")
        setAnimeDesc("")
        setAnimeImageUrl("")
        setAnimeTrailerUrl("")
        setShowFormUpdateAnimeName(false)
        setShowFormUpdateAnimeDesc(false)
        setShowFormUpdateAnimeImageUrl(false)
        setShowFormUpdateAnimeTrailerUrl(false)
        closeUpdateAnimeModal()
    }

    async function handleDeleteAnime() {
        //Call API Delete Anime
        const deleteAnime = await deleteAnimeService(animeId, token)

        if (deleteAnime === '401') {
            alert("Invalid field not correct")
            return
        } else if (deleteAnime === '500') {
            alert("System error")
            return
        }

        dispatch({
            type: DELETE_ANIME_ACTION,
            payload: animeId
        })

        alert("Delete anime success")
        setAnimeName("")
        closeDeleteAnimeModal()
    }

    function handleFetchAnimeUrlChange(event) {
        setFetchAnimeUrl(event.target.value)
    }

    function handleEspAnimeNameChange(event) {
        setEspAnimeName(event.target.value)
    }

    function handleEspAnimeWebsiteChange(event) {
        setEspAnimeWebsite(event.target.value)
    }

    async function handleCreateEsp() {
        //Call API Create Esp
        const createEsp = await createEspService(animeId, espAnimeWebsite, fetchAnimeUrl, token)

        const getAllExternalWebKey = {}
        websites.forEach((externalWeb) => {
            getAllExternalWebKey[externalWeb.externalWebId] = externalWeb.website
        })

        if (createEsp === '401') {
            alert("Invalid field not correct")
            return
        } else if (createEsp === '409') {
            alert("Esp already exist")
            return
        } else if (createEsp === '500') {
            alert("System error")
            return
        }
        const esp = []

        if (createEsp.espEpisode.length === 0) {
            esp.push({
                espId: createEsp.esp.espId,
                website: getAllExternalWebKey[createEsp.esp.externalWebId],
                fetchUrl: createEsp.esp.url,
                lastEp: createEsp.esp.lastEp,
                status: createEsp.esp.status,
                espEpId: "",
                nameEp: "",
                epUrl: ""
            })
        } else {
            let count = 0
            for (const espAnime of createEsp.espEpisode) {
                if (count === 0) {
                    esp.push({
                        espId: createEsp.esp.espId,
                        website: getAllExternalWebKey[espAnime.externalWebId],
                        fetchUrl: createEsp.esp.url,
                        lastEp: createEsp.esp.lastEp,
                        status: createEsp.esp.status,
                        espEpId: espAnime.espEpId,
                        nameEp: espAnime.nameEpisode,
                        epUrl: espAnime.episodeUrl
                    })
                } else {
                    esp.push({
                        espId: "",
                        website: "",
                        fetchUrl: "",
                        lastEp: "",
                        status: "",
                        espEpId: espAnime.espEpId,
                        nameEp: espAnime.nameEpisode,
                        epUrl: espAnime.episodeUrl
                    })
                }

                count += 1
            }
        }

        dispatch({
            type: CREATE_ESP_ACTION,
            payload: {animeId, esp}
        })

        alert("Create Esp Success")
        setEspAnimeName("")
        setEspAnimeWebsite("")
        setFetchAnimeUrl("")
        closeCreateEspModal()
    }

    async function handleUpdateEsp() {
        // Call API Update Esp
        const updateEsp = await updateEspService(espAnimeName,fetchAnimeUrl,token)

        if (updateEsp === '401') {
            alert("Invalid field not correct")
            return
        } else if (updateEsp === '500') {
            alert("System error")
            return
        }

        alert("Update esp success")
        setEspAnimeName("")
        setFetchAnimeUrl("")
        closeUpdateEspModal()
    }

    async function handleDeleteEsp() {
        //Call API Delete Esp
        const deleteEsp = await deleteEspService(espAnimeName,token)

        if (deleteEsp === '401') {
            alert("Invalid field not correct")
            return
        } else if (deleteEsp === '500') {
            alert("System error")
            return
        }

        alert("Delete esp success")
        setEspAnimeName("")
        closeDeleteEspModal()
    }

    function openCreateAnimeModal() {
        setCreateAnimeIsOpen(true);
    }

    function closeCreateAnimeModal() {
        setCreateAnimeIsOpen(false);
    }

    function openUpdateAnimeModal() {
        setUpdateAnimeIsOpen(true);
    }

    function closeUpdateAnimeModal() {
        setUpdateAnimeIsOpen(false);
    }

    function openDeleteAnimeModal() {
        setDeleteAnimeIsOpen(true);
    }

    function closeDeleteAnimeModal() {
        setDeleteAnimeIsOpen(false);
    }

    function openCreateEspModal() {
        setCreateEspIsOpen(true);
    }

    function closeCreateEspModal() {
        setCreateEspIsOpen(false);
    }

    function openUpdateEspModal() {
        setUpdateEspIsOpen(true);
    }

    function closeUpdateEspModal() {
        setUpdateEspIsOpen(false);
    }

    function openDeleteEspModal() {
        setDeleteEspIsOpen(true);
    }

    function closeDeleteEspModal() {
        setDeleteEspIsOpen(false);
    }

    function getOptionAnimeWebsiteComponent() {
        return websites.map((website) => {
            // if (website.website === ANIME_SUGOI) {
            //     return <option key={website.externalWebId} value={website.externalWebId} disabled>{website.website}</option>
            // }
            return <option key={website.externalWebId} value={website.externalWebId}>{website.website}</option>
        })
    }

    function getOptionAnimeNameComponent() {
        return adminAnimes.map((row) => {
            return <option key={row.animeId} value={row.animeId}>{row.animeName}</option>
        })
    }

    function getOptionEspIdComponent() {
        const espIdList = []

        adminAnimes.forEach((row) => {
            row.esp.forEach((esp) => {
                if (esp.espId) {
                    espIdList.push(esp.espId)
                }
            })
        })
        return espIdList.map((espId) => {
            return <option key={espId} value={espId}>{espId}</option>
        })
    }

    function getModalCreateAnime() {
        return (<Modal show={modalCreateAnimeIsOpen} onHide={closeCreateAnimeModal} size="sm">
            <Modal.Header closeButton>
                <Modal.Title>Create Anime</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formAnimeName">
                        <Form.Label>Anime Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter anime name"
                                      onChange={handleAnimeNameChange} value={animeName}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formDesc">
                        <Form.Label>Anime Description</Form.Label>
                        <Form.Control type="text" placeholder="Enter anime description"
                                      onChange={handleAnimeDescChange} value={animeDesc}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formImageUrl">
                        <Form.Label>Anime Image URL</Form.Label>
                        <Form.Control type="text" placeholder="Enter anime image url"
                                      onChange={handleAnimeImageUrlChange} value={animeImageUrl}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formTrailerUrl">
                        <Form.Label>Anime Trailer URL</Form.Label>
                        <Form.Control type="text" placeholder="Enter anime trailer url"
                                      onChange={handleAnimeTrailerUrlChange} value={animeTrailerUrl}/>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={closeCreateAnimeModal}>Close</Button>
                <Button variant="secondary" onClick={handleCreateAnime}>Create</Button>
            </Modal.Footer>
        </Modal>)
    }

    function getModalUpdateAnime() {
        return (<Modal show={modalUpdateAnimeIsOpen} onHide={closeUpdateAnimeModal} size="sm">
            <Modal.Header closeButton>
                <Modal.Title>Update Anime</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formUpdateAnimeName">
                        <Form.Label>Anime Name</Form.Label>
                        <Form.Control as="select" onChange={handleAnimeIdChange}
                                      value={animeId}>
                            <option selected="selected">Choose Anime Name</option>
                            {getOptionAnimeNameComponent()}</Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check inline type="checkbox" label="Update Anime Name"
                                    onClick={handleCheckBoxUpdateAnimeName}/>
                        <Form.Check inline type="checkbox" label="Update Anime Desc"
                                    onClick={handleCheckBoxUpdateAnimeDesc}/>
                        <Form.Check inline type="checkbox" label="Update Anime ImageUrl"
                                    onClick={handleCheckBoxUpdateAnimeImageUrl}/>
                        <Form.Check inline type="checkbox" label="Update Anime TrailerUrl"
                                    onClick={handleCheckBoxUpdateAnimeTrailerUrl}/>
                    </Form.Group>
                    {showFormUpdateAnimeName ? <Form.Group className="mb-3" controlId="formUpdateAnimeName">
                        <Form.Label>Anime Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter anime name for update"
                                      onChange={handleAnimeNameChange} value={animeName}/>
                    </Form.Group> : null}
                    {showFormUpdateAnimeDesc ? <Form.Group className="mb-3" controlId="formUpdateAnimeDesc">
                        <Form.Label>Anime Description</Form.Label>
                        <Form.Control type="text" placeholder="Enter anime description for update"
                                      onChange={handleAnimeDescChange} value={animeDesc}/>
                    </Form.Group> : null}
                    {showFormUpdateAnimeImageUrl ? <Form.Group className="mb-3" controlId="formUpdateAnimeImageUrl">
                        <Form.Label>Anime Image URL</Form.Label>
                        <Form.Control type="text" placeholder="Enter anime image url for update"
                                      onChange={handleAnimeImageUrlChange} value={animeImageUrl}/>
                    </Form.Group> : null}
                    {showFormUpdateAnimeTrailerUrl ? <Form.Group className="mb-3" controlId="formUpdateAnimeTrailerUrl">
                        <Form.Label>Anime Trailer URL</Form.Label>
                        <Form.Control type="text" placeholder="Enter anime trailer url for update"
                                      onChange={handleAnimeTrailerUrlChange} value={animeTrailerUrl}/>
                    </Form.Group> : null}

                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={closeUpdateAnimeModal}>Close</Button>
                <Button variant="secondary" onClick={handleUpdateAnime}>Update</Button>
            </Modal.Footer>
        </Modal>)
    }

    function getModalDeleteAnime() {
        return (<Modal show={modalDeleteAnimeIsOpen} onHide={closeDeleteAnimeModal} size="sm">
            <Modal.Header closeButton>
                <Modal.Title>Delete Anime</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formDeleteAnimeName">
                        <Form.Label>Are you sure to delete this anime ?</Form.Label>
                        <Form.Control as="select" onChange={handleAnimeIdChange}
                                      value={animeId}>
                            <option selected="selected">Choose Anime Name</option>
                            {getOptionAnimeNameComponent()}</Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={closeDeleteAnimeModal}>Close</Button>
                <Button variant="secondary" onClick={handleDeleteAnime}>Confirm</Button>
            </Modal.Footer>
        </Modal>)
    }

    function getModalCreateEsp() {
        return (<Modal show={modalCreateEspIsOpen} onHide={closeCreateEspModal} size="sm">
            <Modal.Header closeButton>
                <Modal.Title>Create Esp</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formEspAnimeName">
                        <Form.Label>Anime Name</Form.Label>
                        <Form.Control as="select" onChange={handleAnimeIdChange}
                                      value={animeId}>
                            <option selected="selected">Choose Anime Name</option>
                            {getOptionAnimeNameComponent()}</Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEspAnimeWebsite">
                        <Form.Label>Anime Website</Form.Label>
                        <Form.Control as="select" onChange={handleEspAnimeWebsiteChange}
                                      value={espAnimeWebsite}>
                            <option selected="selected">Choose Anime Website</option>
                            {getOptionAnimeWebsiteComponent()}</Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formAnimeFetchUrl">
                        <Form.Label>Anime Fetch URL</Form.Label>
                        <Form.Control type="text" placeholder="Enter url for fetch anime"
                                      onChange={handleFetchAnimeUrlChange} value={fetchAnimeUrl}/>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={closeCreateEspModal}>Close</Button>
                <Button variant="secondary" onClick={handleCreateEsp}>Create</Button>
            </Modal.Footer>
        </Modal>)
    }

    function getUpdateModalEsp() {
        return (<Modal show={modalUpdateEspIsOpen} onHide={closeUpdateEspModal} size="sm">
            <Modal.Header closeButton>
                <Modal.Title>Update Esp</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formUpdateEspAnimeName">
                        <Form.Label>Esp ID</Form.Label>
                        <Form.Control as="select" onChange={handleEspAnimeNameChange}
                                      value={espAnimeName}>
                            <option selected="selected">Choose Esp ID</option>
                            {getOptionEspIdComponent()}</Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formUpdateAnimeFetchUrl">
                        <Form.Label>Anime Fetch URL</Form.Label>
                        <Form.Control type="text" placeholder="Enter url for update fetch anime"
                                      onChange={handleFetchAnimeUrlChange} value={fetchAnimeUrl}/>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={closeUpdateEspModal}>Close</Button>
                <Button variant="secondary" onClick={handleUpdateEsp}>Update</Button>
            </Modal.Footer>
        </Modal>)
    }

    function getDeleteModalEsp() {
        return (<Modal show={modalDeleteEspIsOpen} onHide={closeDeleteEspModal} size="sm">
            <Modal.Header closeButton>
                <Modal.Title>Delete Esp</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formUpdateEspAnimeName">
                        <Form.Label>Are you sure to delete this Esp ?</Form.Label>
                        <Form.Control as="select" onChange={handleEspAnimeNameChange}
                                      value={espAnimeName}>
                            <option selected="selected">Choose Esp ID</option>
                            {getOptionEspIdComponent()}</Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={closeDeleteEspModal}>Close</Button>
                <Button variant="secondary" onClick={handleDeleteEsp}>Confirm</Button>
            </Modal.Footer>
        </Modal>)
    }

    return (
        !account || !account.roles.includes('admin') ? <h1>Can't access this page</h1> :
            <>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>Anime ID</TableCell>
                                <TableCell>Anime Name</TableCell>
                                <TableCell align="right">Description</TableCell>
                                <TableCell align="right">Image URL</TableCell>
                                <TableCell align="right">Trailer URL</TableCell>
                                <TableCell align="right">Last EP</TableCell>
                                <TableCell align="right">Latest Last EP</TableCell>
                            </TableRow>
                        </TableHead>
                        {!isFetch.isFetch ? null : <TableBody>
                            {adminAnimes.map((row) => {
                                return <Row key={row.animeId} row={row}/>
                            })}
                        </TableBody>}
                    </Table>
                </TableContainer>
                <div>
                    <div>
                        <Button className="ml-2 mt-4" size="sm" variant="secondary" onClick={openCreateAnimeModal}>Create
                            Anime</Button>
                        <Button className="ml-2 mt-4" size="sm" variant="secondary" onClick={openUpdateAnimeModal}>Update
                            Anime</Button>
                        <Button className="ml-2 mt-4" size="sm" variant="secondary" onClick={openDeleteAnimeModal}>Delete
                            Anime</Button>
                    </div>
                    <br/>
                    <div>
                        <Button className="ml-2 mt-2" size="sm" variant="secondary" onClick={openCreateEspModal}>Create
                            Esp</Button>
                        <Button className="ml-2 mt-2" size="sm" variant="secondary" onClick={openUpdateEspModal}>Update
                            Esp</Button>
                        <Button className="ml-2 mt-2" size="sm" variant="secondary" onClick={openDeleteEspModal}>Delete
                            Esp</Button>
                    </div>
                    {!isFetch.isFetch ? null : <>
                        {getModalCreateAnime()}
                        {getModalUpdateAnime()}
                        {getModalDeleteAnime()}
                        {getModalCreateEsp()}
                        {getUpdateModalEsp()}
                        {getDeleteModalEsp()}
                    </>}
                </div>
            </>
    )
}
