import '../style/Landing.css'
import Video from "../components/Video";
import {Col, Pagination, Row} from "antd";
import AnimeCard from "../components/AnimeCard";
import {useDispatch, useSelector} from "react-redux";
import {useMemo} from "react";
import {getAllAnimePaginationService} from "../api/animeService";
import {FETCH_ANIMES} from "../actions/types";
export default function Landing(){
    const animeList = useSelector(state => state.animes.animes)
    const currentPage = useSelector(state => state.animes.currentPage)
    const totalPage = useSelector(state => state.animes.totalPage)
    const dispatch = useDispatch();

    useMemo(() => {
        async function fetchAnime() {
            const animes = await getAllAnimePaginationService(currentPage);
            dispatch({
                type: FETCH_ANIMES,
                payload: animes
            })
        }

        fetchAnime();
    }, [dispatch,currentPage])

    const onChange = async (page) => {
        const animes = await getAllAnimePaginationService(page);
        dispatch({
            type: FETCH_ANIMES,
            payload: animes
        })
    };

    const anime = {
        id: '1',
        name: 'Kage no Jitsuryokusha ni Naritakute!',
        img: '1.jpg',
        rating: 8.9,
        favorite: false,
        notify: false,
    }

    return (
        <div className="container-landing">
            {/* Video Header*/}
            <div>
                <Video anime={anime}/>
            </div>

            <div>
                <h2 className="ps-3 ps-sm-4 pt-4 pb-0 mt-1 mb-1 text-decoration-underline" style={{fontFamily: 'Kanit, sans-serif'}}>
                    อัพเดทล่าสุด
                </h2>
            </div>

            {/* Anime Content*/}
            <div className="overflow-hidden p-3 p-sm-4">
                <Row gutter={[16, 16]}>
                    {animeList.map((anime) => {
                        return (
                            <Col key={anime.animeId} xs={12} sm={14} xxl={6} xl={6} md={6} lg={6}>
                                <AnimeCard key={anime.animeId} anime={anime}/>
                            </Col>
                        )
                    })}

                </Row>
            </div>

            <div className="d-flex justify-content-center p-3">
                <Pagination className ="anime-pagination" defaultCurrent={currentPage} pageSize={20} total={20 * totalPage} onChange={onChange} />
            </div>

</div>
)
}