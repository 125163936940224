import axios from "axios";

export const api = axios.create({
    baseURL: "https://loner-backend.website/anime-portal/api"
})

export const BASE_ANIME_ENDPOINT = "/animes"
export const BASE_ANIME_WITH_PARAM_ENDPOINT = (animeId) => `${BASE_ANIME_ENDPOINT}/${animeId}`


export const BASE_EXTERNAL_WEB_ENDPOINT = "/external-webs"
export const GET_EXTERNAL_WEB_ENDPOINT = (externalWebId) => `${BASE_EXTERNAL_WEB_ENDPOINT}/${externalWebId}`
export const BASE_ACCOUNT_ENDPOINT = "/accounts"
export const GET_ACCOUNT_ENDPOINT = `${BASE_ACCOUNT_ENDPOINT}/me`
export const LOGIN_ENDPOINT = `${BASE_ACCOUNT_ENDPOINT}/login`
export const LOGOUT_ENDPOINT = `${BASE_ACCOUNT_ENDPOINT}/logout`

export const BASE_SUBSCRIBE_ENDPOINT = "/subscribes"
export const BASE_SUBSCRIBE_WITH_PARAM_ENDPOINT = (animeId) => `${BASE_SUBSCRIBE_ENDPOINT}/${animeId}`
export const BASE_ESP_ENDPOINT = "/esps"
export const BASE_ESP_WITH_PARAM_ENDPOINT = (espId) => `${BASE_ESP_ENDPOINT}/${espId}`

export const BASE_NOTIFICATION_ENDPOINT = "/notifications/line"

// 1 hr = 60 *60 * 1000
export const TOKEN_EXPIRE_TIME = 60 * 60 * 1000

export const GET_AUTHORIZE_HEADER_WITH_TOKEN = (token) => {
    return {
        headers: {
            'Authorization': "Bearer " + token,
            "User-Agent": "got-scraping/1.3.9"
        }
    }
}

export const ANIME_SUGOI = 'animeSugoi'

export const RESPONSE_TYPE = "code"
export const LINE_CLIENT_ID = "1655708723"
export const LINE_REDIRECT_URL = "https://anime-portal.online/profile"
// export const LINE_REDIRECT_URL = "http://localhost:3000/profile"
export const LINE_STATE = "12345"
export const LINE_SCOPE = "profile%20openid"

export const REGISTER_NOTIFICATION_LINE_URL = `https://access.line.me/oauth2/v2.1/authorize?response_type=${RESPONSE_TYPE}&client_id=${LINE_CLIENT_ID}&redirect_uri=${LINE_REDIRECT_URL}&state=${LINE_STATE}&scope=${LINE_SCOPE}`
