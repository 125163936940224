import {Route, Switch, withRouter} from 'react-router-dom';
import React from "react";
import Layout from "./layout/Layout";
import Landing from "./landing/Landing";
import AnimeDetail from "./components/AnimeDetail";
import ManageAnime from "./components/ManageAnime";
import Profile from "./components/Profile";
import NoMatch from "./components/NoMatch";

const App = () => {

    return (
        <Layout>
            <Switch>
                <Route path="/" exact><Landing/></Route>
                <Route path="/anime/:animeId" exact><AnimeDetail/></Route>
                <Route path="/manageAnime" exact><ManageAnime/></Route>
                <Route path="/profile" exact><Profile/></Route>
                <Route component={NoMatch}/>
            </Switch>
        </Layout>
    )
}

export default withRouter(App)