import {
    CREATE_ANIME_ACTION, CREATE_ESP_ACTION,
    DELETE_ANIME_ACTION,
    FETCH_ANIMES_ADMIN,
    LOGOUT_ADMIN_ACTION,
    UPDATE_ANIME_ACTION
} from "../actions/types"

const initialState = {}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ANIMES_ADMIN:
            return {
                ...state,
                adminAnimes: action.payload.adminAnimes,
                websites: action.payload.websites
            }
        case LOGOUT_ADMIN_ACTION:
            return {}
        case CREATE_ANIME_ACTION:
            const oldAnimes = [...state.adminAnimes]
            oldAnimes.push(action.payload)
            return {
                ...state,
                adminAnimes: oldAnimes
            }
        case UPDATE_ANIME_ACTION:
            const adminAnimes = [...state.adminAnimes]
            const payload = action.payload

            adminAnimes.forEach((row) => {
                if (row.animeId === payload.animeId) {
                    for (const [key, value] of Object.entries(payload)) {
                        row[key] = value
                    }
                }
            })

            return {
                ...state,
                adminAnimes
            }
        case DELETE_ANIME_ACTION:
            const deleteAnimes = [...state.adminAnimes]
            deleteAnimes.splice(deleteAnimes.findIndex(anime => anime.animeId === action.payload), 1)

            return {
                ...state,
                adminAnimes: deleteAnimes
            }

        case  CREATE_ESP_ACTION:
            const espPayload = action.payload;
            const createEspAdminAnimes = [...state.adminAnimes]

            for(const anime of createEspAdminAnimes){
                if(anime.animeId === espPayload.animeId){
                    anime.esp = espPayload.esp
                }
            }

            return {
                ...state,
                adminAnimes: createEspAdminAnimes
            }
        default:
            return state
    }
}