import '../style/AnimeDetail.css'
import {Button, Spin, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {BellFilled, BellOutlined, HeartFilled, HeartOutlined} from "@ant-design/icons";
import {getAnimeService} from "../api/animeService";
import {getExternalWebService} from "../api/externalWebService";
import {SUBSCRIBE_ACTION, UNSUBSCRIBE_ACTION, WATCH_ANIME} from "../actions/types";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Collapse from "./Collapse";
import {subscribeService, unsubscribeService} from "../api/subscribeService";

export default function AnimeDetail(){
    const [isLoading, setLoading] = useState(true);
    const [isFavorite, setFavorite] = useState(false);

    const params = useParams()
    const animeId = atob(params.animeId)

    const animeDetail = useSelector(state => state.animes.watchingAnime)
    const isLogin = useSelector(state => state.login.isLogin)
    const account = useSelector(state => state.login.account)
    const token = useSelector(state => state.login.token)
    const dispatch = useDispatch()

    const notifyStatus = isLogin ? account.animeIds.includes(animeId) : false;
    const [isNotify, setNotify] = useState(notifyStatus);

    useEffect(() => {
        async function fetchWatchAnime() {

            const anime = await getAnimeService(animeId)

            const espList = await Promise.all(anime.espWithEpisodes.map(async (esp) => {
                const externalWeb = await getExternalWebService(esp.esp.externalWebId)

                return {...esp, externalWeb}
            }))

            dispatch({
                type: WATCH_ANIME,
                payload: {
                    ...anime,
                    espWithEpisodes: espList
                }
            })
            setLoading(false)
        }

        fetchWatchAnime()
    }, [dispatch, animeId])

    async function handleNotify(){
        if(!isNotify){
            const subscribe = await subscribeService(animeId,token)

            if(subscribe==='404'){
                alert("Subscribe not found")
                return
            }else if(subscribe==='400'){
                alert("Session timeout")
                return
            }else if(subscribe==='500'){
                alert("System error")
                return
            }

            const animeIds = account.animeIds
            animeIds.push(animeId)

            dispatch({
                type: SUBSCRIBE_ACTION,
                payload: animeIds
            })
        }else {
            const unsubscribe = await unsubscribeService(animeId,token)

            if(unsubscribe==='404'){
                alert("Subscribe not found")
                return
            }else if(unsubscribe==='400'){
                alert("Session timeout")
                return
            }else if(unsubscribe==='500'){
                alert("System error")
                return
            }

            const animeIds = account.animeIds
            animeIds.splice(animeIds.findIndex(element=>element===animeId),1)

            dispatch({
                type: UNSUBSCRIBE_ACTION,
                payload: animeIds
            })
        }

        setNotify(!isNotify)
    }

    function handleFavorite(){
        setFavorite(!isFavorite)
    }

    function getSubscribeButtonComponent() {
        if (account.animeIds.includes(animeId)) {
            return <Button className="w-100" type={"primary"} size={"large"} style={{backgroundColor: '#36a400'}} onClick={handleNotify}>
                    <span><BellFilled/> LINE Unsubscribe</span>
                </Button>
        }
        return <Button className="w-100" type={"primary"} size={"large"} style={{backgroundColor: '#36a400'}} onClick={handleNotify}>
            <span><BellOutlined/> LINE Subscribe</span>
        </Button>
    }

    function getAnimeStatus(){
        const status = []

        animeDetail?.espWithEpisodes.forEach((esp)=>{
            status.push(esp.esp.status)
            return
        })

        if(status.includes("ending")){
            return "Ending"
        }
        return "Ongoing"
    }

    function capitalizeFirstCharacter(str) {
        if (!str) {
            return ''; // Return an empty string if the input string is empty or null
        }
        // Convert the first character to uppercase and concatenate with the rest of the string
        return str.charAt(0).toUpperCase() + str.substring(1);
    }

    function getWebsiteName(webName, status){
        const webStatus = status === "process" ? "Ongoing" : "Ending"

        return capitalizeFirstCharacter(webName).concat("  (").concat(webStatus).concat(")")
    }

    return (
        isLoading ? <div className="container detail p-4">
            <Spin size="large"/>
        </div> : (<div  key={animeId} className="container detail p-4">
            <div className="d-block d-lg-flex">
            <div id="left">
                    <div className="image-cover">
                        <img src={animeDetail.imageUrl} alt=""/>
                    </div>
                    {
                        isLogin ?
                            <div className="mt-3">
                                <div>{getSubscribeButtonComponent()}</div>
                                <div className="mt-3">
                                    <Button className="w-100" type={"primary"} size={"large"}
                                            style={{backgroundColor: '#dc3545'}} onClick={handleFavorite}>
                                        {
                                            isFavorite ?
                                                <span><HeartFilled/> Add to Favorite</span> :
                                                <span><HeartOutlined/> Favorite</span>
                                        }
                                    </Button>
                                </div>
                            </div> : null
                    }
                    <div className="anime-detail-box w-100 mt-3 me-3">
                        <div><h3 style={{fontFamily: "Kanit, sans-serif"}}>Episodes: {animeDetail.lastEp}</h3></div>
                        <hr/>
                        <div>Score: 8.9</div>
                        <div>Rank: #26</div>
                        <hr/>
                        <div>Status: <Tag>{getAnimeStatus()}</Tag></div>
                        <hr/>
                        <div>
                            Genres:
                            <Tag>Action</Tag>
                            <Tag>Fantasy</Tag>
                        </div>
                    </div>
                </div>
                <div className="w-100 pt-3 pt-md-0 ps-0 ps-lg-3" id="left">
                    <div>
                        <div className="mt-3 mt-lg-0">
                            <iframe
                                title="video-head"
                                className="iframe"
                                width="100%"
                                height="400"
                                src={animeDetail.trailerUrl}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="anime-detail-box mt-2">
                        <div className="title" style={{fontFamily: "Kanit, sans-serif"}}>{animeDetail.animeName}</div>
                        <hr/>
                        <div className="description"
                             style={{fontFamily: "Kanit, sans-serif"}}>{animeDetail.description}</div>
                    </div>
                    <div className="row gy-2 mt-3 p-4">
                        {
                            animeDetail && animeDetail.espWithEpisodes ?
                                animeDetail?.espWithEpisodes.map((espEp) => {
                                    const web = {
                                        name: getWebsiteName(espEp.externalWeb.website, espEp.esp.status),
                                        data: espEp.espEpisode
                                    }
                                    return <Collapse key= {espEp.externalWeb.website} web={web}/>
                                }) : null
                        }
                    </div>
                </div>
            </div>
        </div>)
    )
}