import {
    LOGIN_ACTION,
    LOGIN_SYSTEM_FAILED,
    LOGIN_VALIDATION_FAILED,
    LOGOUT_ACTION, REGISTER_LINE_NOTIFICATION_ACTION, REMOVE_LINE_NOTIFICATION_ACTION,
    SUBSCRIBE_ACTION, UNSUBSCRIBE_ACTION
} from "../actions/types";

const initialState = {
    isLogin: false,
    error: ''
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_ACTION:
            // 3600000
            const expireMillisec = 3600000
            const tokenExpire = Date.now() + expireMillisec
            return {
                ...state,
                isLogin: true,
                error: '',
                token: action.payload.token,
                account: action.payload.account,
                tokenExpire
            }
        case LOGOUT_ACTION:
            return {
                isLogin: false,
                error: ''
            }
        case LOGIN_VALIDATION_FAILED:
            return {
                ...state,
                error: action.payload
            }
        case LOGIN_SYSTEM_FAILED:
            return {
                ...state,
                error: action.payload
            }
        case SUBSCRIBE_ACTION:
            const oldAccount = {...state.account}
            oldAccount.animeIds = action.payload

            return {
                ...state,
                account: oldAccount
            }
        case UNSUBSCRIBE_ACTION:
            const unsubAccount = {...state.account}
            unsubAccount.animeIds = action.payload
            return {
                ...state,
                account: unsubAccount
            }
        case REGISTER_LINE_NOTIFICATION_ACTION:
            const registerLineAccount = {...state.account}
            registerLineAccount.userId = action.payload
            return {
                ...state,
                account: registerLineAccount
            }
        case REMOVE_LINE_NOTIFICATION_ACTION:
            const removeLineAccount = {...state.account}
            delete removeLineAccount.userId
            return {
                ...state,
                account: removeLineAccount
            }
        default:
            return state
    }
}