import React from 'react';
import '../style/Video.css';
import {Button} from "antd";
import srcVideo from '../asset/videos/1.mp4'
import {CaretRightOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

export default function Video({anime}){
    return (
        <div className="player">
            <div className="video-detail p-5">
                <h2>{anime.name}</h2>
                <div>
                    <h5>ชีวิตไม่ต้องเด่น ขอแค่เป็นเทพในเงา</h5>
                </div>
                <div>
                    <span><div>Genres: Action, Fantasy</div></span>
                    <span>rating {anime.rating}</span>
                </div>
                <div>

                    <Link to = "/anime/OWM2NGExOTItNWQ1NS00NDlkLWI0NjctNTcxMzc4MWZhNjNm">
                    <Button
                        className="play-button mt-3"
                        size="large"
                        shape="round"
                        style={{fontFamily: "Kanit, sans-serif"}}
                    >
                        <CaretRightOutlined />
                        เล่น
                    </Button>
                    </Link>
                </div>
            </div>

            <video autoPlay={true} src={srcVideo} width="100%"  muted={true} ></video>
        </div>

    )
}