import '../style/AnimeCard.css'
import {Link} from "react-router-dom";
export default function AnimeCard({anime}){

    return (
        <Link to={`/anime/${btoa(anime.animeId)}`}>
            <div>
                <div className="anime-card">
                    <div className="anime-image">
                        <img src={anime.imageUrl} alt={anime.animeId}/>
                    </div>
                    <div className="box">
                        <div>{anime.animeName}</div>
                        <div className="box-description">
                            {/*<div>Status: Currently Airing</div>*/}
                            <div>Genres: Action, Fantasy</div>
                        </div>
                    </div>
                </div>

                <div className="box-left-top">
                    <div className="ep">Ep. {anime.latestLastEp}</div>
                    <div className="rating mt-1">{anime.rating}</div>
                </div>

            </div>
        </Link>
    )
}