import '../style/LoginForm.css'
import {Form, Input, Button, Radio, DatePicker} from 'antd';
import {UserOutlined, LockOutlined, SignatureOutlined, MailOutlined} from '@ant-design/icons';
import {createAccountService} from "../api/accountService";

export default function RegisterForm() {
    const [form] = Form.useForm();
    const handleSubmit = async (values) => {
        const account = {
            username: values.userName,
            password: values.password,
            profile:{
                firstName: values.firstName,
                lastName: values.lastName,
                gender: values.gender,
                email: values.email,
                birth: values.birth.valueOf() // convert to timestamp
            }
        }

        const res = await createAccountService(account)

        if(res === '409'){
            alert("Username already used")
            return
        }else if(res === '400') {
            alert("Input field not correct")
            return
        }else if(res === '500'){
            alert("System error")
            return
        }
        alert("Register account success")
        form.resetFields();
    };

    return (
        <div>
            <Form
                form={form}
                className="login-form"
                onFinish={handleSubmit}
            >
                {/* Username field */}
                <Form.Item
                    name="userName"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input
                        prefix={<UserOutlined />}
                        placeholder="Username"
                    />
                </Form.Item>

                {/* Password field */}
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>

                {/* Firstname field */}
                <Form.Item
                    name="firstName"
                    rules={[{ required: true, message: 'Please input your first name!' }]}
                >
                    <Input
                        prefix={<SignatureOutlined />}
                        placeholder="Firstname"
                    />
                </Form.Item>

                {/* Lastname field */}
                <Form.Item
                    name="lastName"
                    rules={[{ required: true, message: 'Please input your last name!' }]}
                >
                    <Input
                        prefix={<SignatureOutlined />}
                        placeholder="Lastname"
                    />
                </Form.Item>

                {/* Email field */}
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input
                        prefix={<MailOutlined />}
                        placeholder="Email"
                    />
                </Form.Item>

                {/* Gender field */}
                <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[{ required: true, message: 'Please choose your gender!' }]}
                >
                    <Radio.Group>
                        <Radio value="male">Male</Radio>
                        <Radio value="female">Female</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Birth"
                    name="birth"
                    rules={[{ required: true, message: 'Please select your birth date!' }]}
                >
                    <DatePicker />
                </Form.Item>

                {/* Register in button */}
                <Form.Item>
                    <Button
                        htmlType="submit"
                        style={{ width: '100%' ,fontFamily: 'Kanit, sans-serif', backgroundColor:'black', color:'white'}}
                        className="login-form-button login-form-margin mt-3 mb-3"
                    >
                        Register
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}


