import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import React, {useEffect, useMemo} from "react";
import {REGISTER_NOTIFICATION_LINE_URL} from "../common";
import {registerLineNotificationService, removeLineNotificationService} from "../api/notificationService";
import {getAccountService} from "../api/accountService";
import {REGISTER_LINE_NOTIFICATION_ACTION, REMOVE_LINE_NOTIFICATION_ACTION} from "../actions/types";

export default function Profile() {
    const isLogin = useSelector(state => state.login.isLogin)
    const account = useSelector(state => state.login.account)
    const token = useSelector(state => state.login.token)
    let query = useQuery();
    const dispatch = useDispatch()

    const style = {marginLeft: "250px", color: "white"}

    function useQuery() {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    }

    useEffect(() => {
        async function registerNotificationLine() {
            const code = query.get("code")
            if (code) {
                const registerLineNotification = await registerLineNotificationService(code, token)

                if (registerLineNotification === '500') {
                    alert("System error")
                    return
                }

                const account = await getAccountService(token)

                if (account === '400') {
                    alert("Account not found")
                    return
                } else if (account === '401') {
                    alert("Session timeout")
                    return
                } else if (account === '500') {
                    alert("System error")
                    return
                }
                const userId = account.profile.userId

                dispatch({
                    type: REGISTER_LINE_NOTIFICATION_ACTION,
                    payload: userId
                })
            }
        }

        registerNotificationLine()
    }, [dispatch, token, query])

    async function handleRemoveRegisterLineNotification() {
        const removeLineNotification = await removeLineNotificationService(token)

        if (removeLineNotification === '500') {
            alert("System error")
            return
        }

        dispatch({
            type: REMOVE_LINE_NOTIFICATION_ACTION,
        })
    }

    function getRegisterNotificationButtonComponent() {
        return !account.userId ?
            <Button variant='danger' style={style} href={REGISTER_NOTIFICATION_LINE_URL}>Register Line
                Notification</Button> : <>
                <Button className="mb-4" variant='danger' style={style}
                        onClick={handleRemoveRegisterLineNotification}>Remove Line
                    Notification</Button>
                <div className="mb-4">
                    <img src="https://i.ibb.co/L99RsLc/qr.png" alt="new" border="0" style={{height: "150px", width: "150px",marginLeft:"250px"}}/>
                </div>
                <p style={style}>Please scan QR for receive notification in line</p>
            </>
    }

    return (
        !isLogin ? <p style={{color: "white"}}>Please Login</p> :
            <div style={{marginTop: "80px"}}>
                <p style={style}>Username: {account.username}</p>
                <p style={style}>Firstname: {account.firstName}</p>
                <p style={style}>Lastname: {account.lastName}</p>
                <p style={style}>E-mail: {account.email}</p>
                {getRegisterNotificationButtonComponent()}
            </div>
    )
}