import {api, BASE_ACCOUNT_ENDPOINT, GET_ACCOUNT_ENDPOINT, GET_AUTHORIZE_HEADER_WITH_TOKEN} from "../common";

export async function createAccountService(account) {
    try {
        await api.post(BASE_ACCOUNT_ENDPOINT, account);
    } catch (e) {
        if (e.toString().includes('409')) {
            return '409'
        } else if (e.toString().includes('400')) {
            return '400'
        }

        return '500'
    }

    return '200'
}

export async function getAccountService(token) {
    let getAccount
    try {
        getAccount = await api.get(GET_ACCOUNT_ENDPOINT, GET_AUTHORIZE_HEADER_WITH_TOKEN(token))
    }catch (e){
        if (e.toString().includes('404')) {
            return '404'
        } else if (e.toString().includes('400') || e.toString().includes('401')) {
            return '400'
        }

        return '500'
    }

    return getAccount.data
}