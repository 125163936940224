import {FETCH_ANIMES, WATCH_ANIME} from "../actions/types"

const initialState = {
    animes: [],
    watchingAnime: {},
    currentPage: 1,
    totalPage: 0,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ANIMES:
            return {
                ...state,
                animes: action.payload.animeList,
                currentPage: action.payload.pageNumber,
                totalPage: Math.floor(action.payload.totalPage),
                watchingAnime: {}
            }

        case WATCH_ANIME:
            return {
                ...state,
                watchingAnime: action.payload
            }
        default:
            return state
    }
}