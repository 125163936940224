import {api, BASE_ESP_ENDPOINT, BASE_ESP_WITH_PARAM_ENDPOINT, GET_AUTHORIZE_HEADER_WITH_TOKEN} from "../common";

export async function createEspService(animeId, externalWebId, url, token) {
    let createEsp
    try {
        createEsp = await api.post(BASE_ESP_ENDPOINT, {
            animeId,
            externalWebId,
            url
        }, GET_AUTHORIZE_HEADER_WITH_TOKEN(token))
    } catch (e) {
        if (e.toString().includes('401') || e.toString().includes('400')) {
            return '401';
        } else if (e.toString().includes('409')) {
            return '409'
        }
        return '500';
    }

    return createEsp.data
}

export async function updateEspService(espId, url, token) {
    let updateEsp
    try {
        updateEsp = await api.put(BASE_ESP_WITH_PARAM_ENDPOINT(espId), {
            url
        }, GET_AUTHORIZE_HEADER_WITH_TOKEN(token))
    } catch (e) {
        if (e.toString().includes('401') || e.toString().includes('400')) {
            return '401';
        }
        return '500';
    }

    return updateEsp.data
}

export async function deleteEspService(espId, token) {
    let deleteEsp
    try {
        deleteEsp = await api.delete(BASE_ESP_WITH_PARAM_ENDPOINT(espId), GET_AUTHORIZE_HEADER_WITH_TOKEN(token))
    } catch (e) {
        if (e.toString().includes('401') || e.toString().includes('400')) {
            return '401';
        }
        return '500';
    }

    return deleteEsp.data
}