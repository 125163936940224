import '../style/Collapse.css'
import React from "react";

import {Collapse as AntCollapse, Empty, List, Tag} from "antd";

const Header = ({ web }) => (
    <span>
    <span style={{color:'white'}}>{web.name}</span>
    <span style={{ position: 'absolute', right: 0 }}>
      <Tag color="#dc3545">Episodes {web.data.length}</Tag>
    </span>
  </span>
);

export default function Collapse({web}){
    return (
        <AntCollapse>
            <AntCollapse.Panel header={<Header web={web} />} key="1">
                <List
                    itemLayout="horizontal"
                    loading={false} // Set this to true if you have loading state
                    dataSource={web.data}
                    renderItem={(item) => (
                        <List.Item>
                            <a key={item.episodeUrl} href={item.episodeUrl}>{item.nameEpisode}</a>
                        </List.Item>
                    )}
                    locale={{ emptyText: <Empty /> }}
                />
            </AntCollapse.Panel>
        </AntCollapse>
    )
}